const bannerConfig = {
  index: [
    {
      type: 1,
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/index-header-banner1.jpg',
      bgColor: '#000233'
    },
    {
      type: 2,
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/index-header-banner2.jpg',
      bgColor: '#13192e'
    },
    {
      type: 3,
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/index-header-banner1.webp',
      bgColor: 'rgb(43,44,59)'
    },
    {
      type: 5,
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/index-header-banner2.webp',
      bgColor: '#14191b'
    }
  ],
  trend: [
    {
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/trend.jpg',
      bgColor: '#000233'
    }
  ],
  mkt: [
    {
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/mkt.jpg',
      bgColor: '#000233'
    }
  ],
  'mkt-favorite': [
    {
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/mkt.jpg',
      bgColor: '#000233'
    }
  ],
  'game-tracker': [
    {
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/game-tracker.jpg',
      bgColor: '#000233'
    }
  ],
  inventory: [
    {
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/inventory.jpg',
      bgColor: '#000233'
    }
  ],
  selection: [
    {
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/selection.jpg',
      bgColor: '#000233'
    }
  ],
  'selection-favorite': [
    {
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/selection.jpg',
      bgColor: '#000233'
    }
  ],
  skindiy: [
    {
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/index/skindiy.jpg',
      bgColor: '#000233'
    }
  ],
  ladders: [
    {
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/ladders/ladders.jpg',
      bgColor: '#000233'
    }
  ],
  'ladders-hanging': [
    {
      imgUrl: 'https://cdn.ok-skins.com/steam-stock/banner/ladders/hangimg.jpg',
      bgColor: '#000233'
    }
  ]
}

export default bannerConfig
